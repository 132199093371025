import React from 'react';
import ReactPlayer from 'react-player';
import { TitleVideo } from '../typography';
import { Divider } from '@mui/material';
import { PlayOn } from '../icons';
import { StackLeft } from '../stack';
import { getThemeContraste } from '../../utils/theme';

export const VideoPlayer = (props) => {
  return (
    <div className="video-player-wrapper">
      <Divider textAlign='left'><StackLeft><PlayOn color={getThemeContraste()} /><TitleVideo title={props.titulo} /></StackLeft></Divider>
      <p />
      <ReactPlayer
        url={props.url}
        controls={true}
        width='100%'
        height='70vh'
      />
    </div>
  );
};

export default VideoPlayer;
