import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TitleGrupos } from '../typography';
import { StackLeft } from "../../components/stack";
import { ButtonGroup, Grid } from '@mui/material';
import { ButtonToolTip } from '../button';
import { Add, Delete, DownIcon, Edit, UpIcon } from '../icons';
import { getThemeContraste } from '../../utils/theme';

//#region AccordionPastas ******************************************************************************************************************************************************************************************************
export function AccordionPastas(props) {
    const [expanded, setExpanded] = React.useState(true);

    return (
        <Accordion expanded={expanded} id='pastas' name='pastas'
            style={{ background: props.cor }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${props.index}Grupo-content`}
                onClick={() => setExpanded(!expanded)}
                style={{ height: '5px', marginBottom: expanded ? '-15px' : '' }}
            >
                <StackLeft>
                    <div style={{ paddingTop: '5px' }}>{props.icon}</div>
                    <TitleGrupos title={props.pasta} />
                </StackLeft>
            </AccordionSummary>
            <AccordionDetails >
                {expanded ? props.children : ''}
            </AccordionDetails>
        </Accordion>
    );
}
//#endregion AccordionPastas ******************************************************************************************************************************************************************************************************

//#region AccordionPastas ******************************************************************************************************************************************************************************************************
export function AccordionPastasConfig(props) {
    const [expanded, setExpanded] = React.useState(false);

    return (
        <Accordion expanded={expanded} id='pastas' name='pastas'
            style={{ background: props.cor }}
        >
            <AccordionSummary
                aria-controls={`panel${props.index}Grupo-content`}
                style={{ height: '5px', marginBottom: expanded ? '-10px' : '' }}
                onClick={props.onClickSelect}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="stretch"
                >
                    <Grid item xs={10} onClick={() => setExpanded(!expanded)} onClickCapture={props.onClickCapture}>
                        <StackLeft>
                            <div style={{ paddingTop: '5px' }}>{props.icon}</div>
                            <TitleGrupos title={props.pasta} />
                        </StackLeft>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'end' }}>
                        {expanded && props.pasta.id !== 1 ?
                            <ButtonGroup>
                                <ButtonToolTip title='Adicionar' icon={<Add fontSize='20px' color={getThemeContraste()} />} onClick={props.onClickAdd} />
                                <ButtonToolTip title='Editar Pasta' icon={<Edit fontSize='20px' color={getThemeContraste()} />} onClick={props.onClickEdit} />
                                <ButtonToolTip title='Remover Pasta' icon={<Delete fontSize='20px' color={getThemeContraste()} />} onClick={props.onClickRemove} />
                            </ButtonGroup>
                            : ''
                        }
                        {props.ocultarMov || expanded ? '' :
                            <ButtonGroup>
                                <ButtonToolTip title='Mover para cima' icon={<UpIcon fontSize='28px' color={getThemeContraste()} />} onClick={props.onClickUp} />
                                <ButtonToolTip title='Mover para baixo' icon={<DownIcon fontSize='28px' color={getThemeContraste()} />} onClick={props.onClickDown} />
                            </ButtonGroup>
                        }
                    </Grid>
                </Grid>
                <StackLeft>
                </StackLeft>
            </AccordionSummary>
            <AccordionDetails >
                {expanded ? props.children : ''}
            </AccordionDetails>
        </Accordion>
    );
}
//#endregion AccordionPastas ******************************************************************************************************************************************************************************************************