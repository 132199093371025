import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import FolderIcon from '@mui/icons-material/Folder';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { getThemeContraste } from '../../utils/theme';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ReplayIcon from '@mui/icons-material/Replay';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';

export function Back(props) {
    return <ReplayIcon style={{ fontSize: props.fontSize, color: props.color }} />
};

export function Power(props) {
    return <PowerSettingsNewIcon style={{ fontSize: props.fontSize, color: props.color }} />
};

export function LigthTheme(props) {
    return <LightModeIcon style={{ fontSize: props.fontSize, color: props.color }} />
};

export function DarkTheme(props) {
    return <DarkModeIcon style={{ fontSize: props.fontSize, color: props.color }} />
};

export function PlayOn(props) {
    return <PlayCircleIcon style={{ fontSize: props.fontSize, color: props.color }} />
};

export function PlayOff(props) {
    return <PlayCircleOutlineIcon style={{ fontSize: props.fontSize, color: props.color }} />
};

export function Folder(props) {
    return <FolderIcon style={{ fontSize: props.fontSize, color: props.color }} />
};

export function SubFolder(props) {
    return <SubdirectoryArrowRightIcon style={{ fontSize: props.fontSize, color: props.color }} />
};

export function Add(props) {
    return <ControlPointOutlinedIcon style={{ fontSize: props.fontSize, color: props.color }} />
};

export function Delete(props) {
    return <DeleteOutlineIcon style={{ fontSize: props.fontSize, color: props.color }} />

};

export function Settings(props) {
    return <SettingsOutlinedIcon style={{ fontSize: props.fontSize, color: props.color }} />
};

export function DeleteFolder(props) {
    return <FolderDeleteIcon style={{ fontSize: props.fontSize, color: props.color }} />
};

export function UploadFile(props) {
    return <CloudUploadIcon style={{ fontSize: props.fontSize, color: props.color }} />
};

export function Edit(props) {
    return <CreateOutlinedIcon style={{ fontSize: props.fontSize, color: props.color }} />
};

export function UpIcon(props) {
    return <KeyboardArrowUpIcon style={{ fontSize: props.fontSize, color: props.color }} />
};

export function DownIcon(props) {
    return <KeyboardArrowDownIcon style={{ fontSize: props.fontSize, color: props.color }} />
};

export function CancelIcon(props) {
    return <CancelOutlinedIcon style={{ fontSize: props.fontSize, color: props.color }} />
};

export function CheckIcon(props) {
    return <CheckOutlinedIcon style={{ fontSize: props.fontSize, color: props.color }} />
};

export function ReloadIcon(props) {
    return <CachedOutlinedIcon style={{ fontSize: props.fontSize, color: props.color }} />
};