import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import { Button, ButtonGroup, Divider, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { getBackground, getTheme, getThemeContraste } from '../../utils/theme';
import { Power, LigthTheme, DarkTheme, Settings, Back } from '../icons';
import { ButtonLinkIcon, ButtonToolTip } from '../button';
import { setLocal } from '../../utils/storage';
import { getUser } from '../../utils/user';

export default function TemporaryDrawer(props) {
    function setTheme(value) {
        setLocal('theme', value)
        window.location.reload();
    }

    return (<>
        <AppBar position="fixed" style={{ background: getBackground(), boxShadow: 'none' }}>
            <Paper elevation={2} style={{ background: getBackground() }}>
                <Container maxWidth="xxl">
                    <Toolbar disableGutters >
                        <Link to='/home'>
                            <Avatar alt="Logo Redsis" src="../../../app-assets/img/logo/logo.png" />

                        </Link>
                        <Box style={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }} />
                        {getUser().administrador ? <ButtonLinkIcon to='/config' title='Config.' icon={<Settings style={{ background: getThemeContraste() }} fontSize='25px' />} /> : ''}
                        <Box  >
                            {getTheme() === 'dark' ?
                                <ButtonToolTip title='Modo Light' icon={<LigthTheme style={{ background: getThemeContraste() }} fontSize='25px' />} onClick={() => setTheme('light')} />
                                :
                                <ButtonToolTip title='Modo Dark' icon={<DarkTheme style={{ background: getThemeContraste() }} fontSize='25px' />} onClick={() => setTheme('dark')} />
                            }
                            <ButtonLinkIcon to='/login' title='Sair' icon={<Power style={{ background: getThemeContraste() }} fontSize='25px' />} onClick={() => sessionStorage.clear()} />
                        </Box>
                    </Toolbar>
                </Container>
            </Paper>
            {props.activeAuxBar ?
                <Paper elevation={0} style={{ background: getBackground(), marginTop: '10px' }}>
                    <Container maxWidth="xxl">
                        <Toolbar disableGutters >
                            <p style={{
                                boxSizing: 'border-box',
                                borderBottom: `2px solid #ed3237`,
                                margin: '0px',
                                fontWeight: '500',
                                fontSize: '0.975rem',
                                letterSpacing: '0.02857em',
                                maxWidth: '200px',
                                padding: '12px 16px 12px',
                                color: getThemeContraste(),
                            }}>
                                {props.title}
                            </p>
                            <Box style={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }} />
                            <Box  >
                                <ButtonGroup
                                    color="primary"
                                    size="large"
                                    variant="text"
                                >
                                    {props.btnAux}
                                    <Link to='/home'><ButtonToolTip title='Voltar' icon={<Back color='orange' />} /></Link>
                                </ButtonGroup>
                            </Box>
                        </Toolbar>
                        <Divider />
                    </Container>
                </Paper>
                : ''}
        </AppBar >
    </>
    );
}