import { useState } from "react";
import axios from "../services/api";
import { getSession, setSession } from "../utils/storage";

const useToken = () => {
    const [mensagem, setMensagem] = useState(undefined);
    const token = 'Bearer ' + getSession('token_sac');


    async function getToken(params) {
        setMensagem(undefined);
        let data = {
            usuario: params.usuario,
            senha: params.senha,
            servico: 'videos',
        }
        await axios.post('/GetToken', data)
            .then(function (response) {
                setSession('token_sac', JSON.stringify(response.data.token).replace(/"/g, ''))
                if (params.usuario === 'VISITANTE')
                    window.location.href = '/visitantes';
                else
                    window.location.href = '/home';
            })
            .catch(function (error) {
                setMensagem(error.response.status + ' - ' + error.response.data.error);
            })
    }

    async function getTokenExterno(cnpj) {
        setMensagem(undefined);
        let data = {
            usuario: cnpj,
            senha: `RED${cnpj}`,
            servico: 'videos',
        }
        await axios.post('/GetToken', data)
            .then(function (response) {
                setSession('token_sac', JSON.stringify(response.data.token).replace(/"/g, ''))
                window.location.href = '/home';
            })
            .catch(function (error) {
                setMensagem(error.response.status + ' - ' + error.response.data.error);
            })
    }

    return { getToken, getTokenExterno, token, mensagem }
}
export default useToken;