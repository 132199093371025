import * as React from 'react';
import Typography from '@mui/material/Typography';
import { getThemeContraste } from '../../utils/theme';

export function TitleVideo(props) {
    return (<Typography fontSize={17} style={{ color: getThemeContraste() }}>
        {props.title}
    </Typography>)
}

export function TitleGrupos(props) {
    return (<Typography fontSize={15} noWrap style={{ color: getThemeContraste() }}>
        {props.title}
    </Typography>)
}