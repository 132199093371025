import { useState } from "react";
import useToken from "./useToken";
import { getSession, setSession } from "../utils/storage";

const useVideos = () => {
    const { token } = useToken();
    const [videos, setVideos] = useState();
    const [msg, setMsg] = useState();
    const [loading, setLoading] = useState(true);

    const getVideos = async (tipo) => {
        setLoading(true)
        let url = 'https://server.redsis.com.br:8085/videos';
        if (tipo)
            url = `https://server.redsis.com.br:8085/videos/${tipo}`;
        await fetch(url, {
            headers: {
                'accept':'application/json',
                'Authorization': token                
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Assuming response is JSON
            })
            .then(data => {
                setVideos(data);
            })
            .catch(error => {
                console.error('There was a problem with your fetch operation:', error);
            });
        setLoading(false);
    }

    const postVideos = async (tipo) => {
        let json = getSession('videos');

        fetch(`https://server.redsis.com.br:8085/videos/${tipo.toLowerCase()}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: json
        })
            .then(response => {
                if (!response.ok) {
                    // throw new Error('Erro ao enviar o arquivo.');
                    setMsg({mensagem: 'Erro ao enviar o arquivo.', severity: 'error'})
                }
                // console.log('Arquivo enviado com sucesso!');
                setMsg({mensagem: 'Arquivo enviado com sucesso!', severity: 'success'})
            })
            .catch(error => {
                setMsg({mensagem: 'Erro na solicitação:' + error, severity: 'error'})
            });
    }


    return { postVideos, getVideos, videos, loading, msg };
}

export default useVideos;

